.cta-wrapper {
  .headline {
    font-family: 'Owners';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: $pf-dark-gray;
  }

  .content-wrapper {
    width: 100%;
  }
}
